<template>
  <!--
    Used to manage the acknowledge messages that are used to acknowledge triggers
  -->
  <div class="acknowledgeMessagesList">
    <LoadingPlaceholder v-if="loading" />
    
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(acknowledgeMessages)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(acknowledgeMessages)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <div
          slot="keyFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="keyFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a key"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('keyFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="messageFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="messageFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a message"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('messageFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <template
          slot="optionsTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div class="btn-group">
              <button
                class="btn btn-primary btn-sm"
                @click="showSidebarEdit(props.dataItem)"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="edit"
                />
                <span>Edit</span>
              </button>
              <button
                class="btn btn-secondary btn-sm"
                @click="openConfirmModal(props.dataItem)"
              >
                <font-awesome-icon
                  class="mr-2 gray"
                  icon="trash"
                />
                <span>Remove</span>
              </button>
            </div>
          </td>
        </template>
      </Grid>
      <Sidebar
        v-if="showSidebar"
        :show-sidebar="showSidebar"
        @close="close"
      >
        <AcknowledgeMessagesAdd
          v-if="addingElement"
          @reload="reloadAcknowledgeMessages"
        />
        <AcknowledgeMessagesEdit
          v-else
          :acknowledge-message="acknowledgeMessage"
          @reload="reloadAcknowledgeMessages"
        />
      </Sidebar>
    </template>

    <SweetModal
      ref="confirmDelete"
      :title="$t('introOutroListComp.deleteElement')"
      icon="warning"
      blocking
      class="overflowHidden"
    >
      <p v-if="acknowledgeMessage">
        {{ $t('sureToDelete', {'0' : acknowledgeMessage.key}) }}
      </p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmDelete.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t('cancel') }}
      </button>
      <button
        slot="button"
        class="btn btn-danger float-right mb-3"
        @click="removeAcknowledgeMessage()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="trash"
        />{{ $t('delete') }}
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>

import { SweetModal } from 'sweet-modal-vue';
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import Sidebar from '@/components/Base/Sidebar.vue';
import AcknowledgeMessagesEdit from '@/components/AcknowledgeMessages/AcknowledgeMessagesEdit.vue';
import AcknowledgeMessagesAdd from '@/components/AcknowledgeMessages/AcknowledgeMessagesAdd.vue';

export default {
  name: "AcknowledgeMessagesList",
  components: {
    SweetModal,
    Sidebar,
    AcknowledgeMessagesEdit,
    AcknowledgeMessagesAdd
  },
  mixins: [
    kendoGridMixin
  ],
  data () {
    return {
      loading: true,
      acknowledgeMessages: null,
      acknowledgeMessage: null,
      showSidebar: false,
      addingElement: false,
      currentId: null,
      deleteIndex: null,
      testImageUrl: null,
      kgm_filter: {
        logic: "and",
        filters: [
          {
            field: 'key',
            operator: 'contains',
            value: ''
          }
        ]
      },
      kgm_sort: [
        {
          "field": "key",
          "dir": "asc"
        }
      ],
      kgm_columns: [
        {
          field: 'key',
          filterable: true,
          filter: 'text',
          title: 'Key',
          filterCell: "keyFilter"
        },
        {
          field: 'message',
          filterable: true,
          filter: 'text',
          title: 'Message',
          filterCell: "messageFilter"
        },
        {
          filterable: false,
          title: 'Options',
          cell: 'optionsTemplate',
          width: '300px'
        }
      ]
    }
  },
  metaInfo () {
    return {
      title: this.$t('menu.acknowledgeMessages')
    }
  },
  created () {
    this.loadAcknowledgeMessages();
  },
  methods: {
    //#region Sidebar
    show () {
      this.showSidebar = true;
    },
    close () {
      this.showSidebar = false;
    },
    showSidebarAdd () {
      this.addingElement = true;
      this.show();
    },
    showSidebarEdit (emitValues) {
      this.setCurrentMessage(emitValues);
      this.addingElement = false;
      this.show();
    },
    setCurrentMessage (message) {
      this.acknowledgeMessage = Object.assign({}, message); //clones Object without reference to fix close sidebar issue
    },
    //#endregion

    //#region API-calls
    loadAcknowledgeMessages () {
      this.loading = true;
      this.getAcknowledgeMessages();
    },
    reloadAcknowledgeMessages () {
      this.showSidebar = false;
      this.$emit("reloadAuditLogs");
      this.getAcknowledgeMessages();
    },
    openConfirmModal (data) {
      this.setCurrentMessage(data);
      this.deleteIndex = data.id; //acknowledgeMessage does not have an Id at the moment
      this.$refs.confirmDelete.open();
    },
    removeAcknowledgeMessage () {
      this.axios.delete(`/AcknowledgeMessages/DeleteAcknowledgeMessage?id=${ this.deleteIndex }`)
        .then(() => {
          this.$snotify.success(this.$t('acknowledgeMessagesComponent.deletedSuccessfully'));
          this.$emit("reloadAuditLogs");
          this.reloadAcknowledgeMessages();
        })
        .finally(() => {
          this.deleteIndex = null;
          this.$refs.confirmDelete.close();
        });
    },
    getAcknowledgeMessages () {
      this.axios.get('/AcknowledgeMessages/GetAllAcknowledgeMessages')
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.acknowledgeMessages = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //#endregion
  }
}
</script>
<style>
.acknowledgeMessagesList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>