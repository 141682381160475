<template>
  <!--
    Used to edit an acknowledgeMessage
  -->
  <div class="acknowledgeMessageEdit">
    <template v-if="acknowledgeMessage">
      <form>
        <div class="form-group">
          <h4 class="m-0 mt-3">
            {{ $t('acknowledgeMessagesComponent.acknowledgeMessage') }}
          </h4>
          <hr class="m-0 mb-3">
          <label>{{ $t('key') }}</label>
          <input
            v-model="acknowledgeMessage.key"
            v-focus
            :class="['form-control', { 'is-invalid': $validator.errors.has('Key') }]"
            type="text"
          >
          <hr class="m-0 mb-3">
          <label>{{ $t('message') }}</label>
          <input
            v-model="acknowledgeMessage.message"
            v-focus
            :class="['form-control', { 'is-invalid': $validator.errors.has('Message') }]"
            type="text"
          >
          <span
            v-show="errors.has('Message')"
            class="badge badge-danger"
          >{{ errors.first('Message') }}</span>
        </div>
        <button
          class="btn btn-sm btn-primary float-right"
          @click.prevent="updateAcknowledgeMessage()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="check"
          />{{ $t('save') }}
        </button>
        <div class="clearfix" />
      </form>
    </template>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "AcknowledgeMessageEdit",
  mixins: [
    errorMixin
  ],
  props: {
    acknowledgeMessage: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: true,
    }
  },
  methods: {
    updateAcknowledgeMessage () {
      let acknowledgeMessageDto = {
        'id': this.acknowledgeMessage.id,
        'key': this.acknowledgeMessage.key,
        'message': this.acknowledgeMessage.message
      };
      this.axios.put('/AcknowledgeMessages/UpdateAcknowledgeMessage', acknowledgeMessageDto)
        .then(() => {
          this.$emit("reload");
          this.error_clear();
          this.$snotify.success(this.$t('acknowledgeMessagesComponent.updatedSuccessfully'));
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>
