<template>
  <!--
    Used to create an acknowledgeMessage
  -->
  <div class="acknowledgeMessagesAdd">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          {{ $t('acknowledgeMessagesComponent.acknowledgeMessage') }}
        </h4>
        <hr class="m-0 mb-3">
        <label>{{ $t('key') }}</label>
        <input
          v-model="acknowledgeMessage.key"
          v-focus
          :class="['form-control', { 'is-invalid': $validator.errors.has('Key') }]"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('message') }}</label>
        <input
          v-model="acknowledgeMessage.message"
          v-focus
          :class="['form-control', { 'is-invalid': $validator.errors.has('Message') }]"
          type="text"
        >
      </div>
      <button 
        class="btn btn-primary float-right" 
        :disabled="!acknowledgeMessage.message || !acknowledgeMessage.key"
        @click.prevent="addAcknowledgeMessage()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="check"
        />{{ $t('add') }}
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "AcknowledgeMessagesAdd",
  mixins: [
    errorMixin
  ],
  data () {
    return {
      acknowledgeMessage: {}
    }
  },
  methods: {
    async addAcknowledgeMessage () {
       let postObject = {
            'key': this.acknowledgeMessage.key,
            'message': this.acknowledgeMessage.message,
      }
      await this.axios.post(`/AcknowledgeMessages/AddAcknowledgeMessage`, postObject)
        .then(() => { 
          this.$snotify.success(this.$t('acknowledgeMessagesComponent.addedSuccessfully'));       
          this.$emit("reload");
          this.error_clear();
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>
<style scoped>
.acknowledgeMessagesAdd .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>
